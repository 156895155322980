import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA2palNFPLpDCZwO7DFtq3ZOWBXUYz0U3g",
  authDomain: "otter-tools-2c46f.firebaseapp.com",
  projectId: "otter-tools-2c46f",
  storageBucket: "otter-tools-2c46f.appspot.com",
  messagingSenderId: "311488498643",
  appId: "1:311488498643:web:3a219338291a3bc048fd26",
  measurementId: "G-MY26N7VTNM"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect, useState } from "react";
import "./StoryGame.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faArrowCircleDown,
  faDownLeftAndUpRightToCenter,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { ImageMatchGamePages, Page, Story } from "./models/story";
import MinimizedStory from "./components/MinimizedStory";

type Props = {
  onCancel: () => void;
};

enum Mode {
  STORY = "story",
  IMAGE_MATCH = "image_match",
}

/**
 * Component to render a decodable story for the student to practice matching the images to sentences
 */
const StoryGame = (props: Props) => {
  const { onCancel } = props;
  const [gameMode, setGameMode] = useState<Mode>(Mode.STORY);
  const [story, setStory] = useState<Story>();
  const [isMinimized, setIsMinimized] = useState(false);
  const [leftPage, setLeftPage] = useState<Page>();
  const [rightPage, setRightPage] = useState<Page>();
  const [imageMatchPages, setImageMatchPages] = useState<ImageMatchGamePages>();
  const [showImageMatchResults, setShowImageMatchResults] = useState(false);
  const [showPlayAgainButton, setShowPlayAgainButton] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadStory = async () => {
      setLoading(true);

      const newStory = await Story.TheTrap();

      setLeftPage(newStory.pages[0]);
      setRightPage(newStory.pages[1]);
      setStory(newStory);
      setLoading(false);
    };

    loadStory();
  }, []);

  const goToPrevPages = () => {
    const newRightPage = story?.getPreviousPage(leftPage);
    const newLeftPage = story?.getPreviousPage(newRightPage);
    setLeftPage(newLeftPage);
    setRightPage(newRightPage);
  };

  const goToNextPages = () => {
    const newLeftPage = story?.getNextPage(rightPage);
    const newRightPage = story?.getNextPage(newLeftPage);
    setLeftPage(newLeftPage);
    setRightPage(newRightPage);
  };

  const loadImageMatchGame = () => {
    setShowImageMatchResults(false);
    setShowPlayAgainButton(false);
    setImageMatchPages(story?.getPagesForImageMatchGame());
    setGameMode(Mode.IMAGE_MATCH);
  };

  const handleStoryMatchImageClick = () => {
    setShowImageMatchResults(true);
    setShowPlayAgainButton(true);
  };

  const minimizeStory = () => setIsMinimized(true);

  if (loading === true || !story) return null;

  const hasPreviousPage = story.hasPreviousPage(leftPage);
  const hasNextPage = story.hasNextPage(rightPage);

  const MinimizeButton = () => (
    <button className="btn btn-link" onClick={minimizeStory}>
      Use Playmat <FontAwesomeIcon icon={faArrowCircleDown} />
    </button>
  );

  if (isMinimized) {
    return (
      <MinimizedStory
        story={story}
        leftPage={leftPage}
        rightPage={rightPage}
        onClick={() => setIsMinimized(false)}
      />
    );
  }

  return (
    <div className={"StoryGameContainer"}>
      <div className="WindowControls">
        <button className="WindowControl" onClick={minimizeStory}>
          <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
        </button>
        <button className="WindowControl" onClick={onCancel}>
          <FontAwesomeIcon icon={faClose} />
        </button>
      </div>
      {gameMode === Mode.STORY && (
        <>
          <div className="StoryPages">
            <div className="StoryPage StoryPageLeft">
              <img className="StoryPageImage" src={leftPage?.image} alt="" />
              <p className="StoryPageText">{leftPage?.text}</p>
              <span className="StoryPageNumber">{leftPage?.number}</span>
            </div>
            <div className="StoryPage StoryPageRight">
              <img className="StoryPageImage" src={rightPage?.image} alt="" />
              <p className="StoryPageText">{rightPage?.text}</p>
              <span className="StoryPageNumber">{rightPage?.number}</span>
            </div>
          </div>
          <div className="StoryControls">
            <button
              onClick={goToPrevPages}
              style={{
                visibility: hasPreviousPage ? "visible" : "hidden",
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Previous
            </button>
            <MinimizeButton />
            {hasNextPage ? (
              <button onClick={goToNextPages}>
                Next <FontAwesomeIcon icon={faArrowRight} />
              </button>
            ) : (
              <button className="btn btn-primary" onClick={loadImageMatchGame}>
                Finish
              </button>
            )}
          </div>
        </>
      )}
      {gameMode === Mode.IMAGE_MATCH && (
        <>
          <p className="StoryPageText">{imageMatchPages?.correctPage?.text}</p>
          <p className="StoryMatchInstruction">
            Pick the image that matches this page of the story
          </p>
          <div className="StoryMatchImages">
            {imageMatchPages?.allPages.map((page) => (
              <div className="StoryMatchImage" key={page.number}>
                <img
                  src={page.image}
                  alt=""
                  style={{
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => handleStoryMatchImageClick()}
                />
                {showImageMatchResults && (
                  <p
                    className="StoryMatchResultText"
                    style={{
                      color:
                        page === imageMatchPages.correctPage ? "green" : "",
                    }}
                  >
                    {page === imageMatchPages.correctPage ? "Correct!" : ""}
                  </p>
                )}
              </div>
            ))}
          </div>
          {showPlayAgainButton && (
            <div className="StoryControls">
              <div />
              <MinimizeButton />
              <button className="btn btn-primary" onClick={loadImageMatchGame}>
                Play Again! <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StoryGame;

import React from "react";
import "./PlayButton.css";

type Props = {
  onClick: () => void;
};

const PlayButton = (props: Props) => {
  return (
    <button className="btn CardPlayButton" onClick={props.onClick}>
      Let's Play!
    </button>
  );
};

export default PlayButton;

import React from "react";
import { Droppable, DroppableProvided } from "react-beautiful-dnd";
import Block from "../models/block";
import BlockPlaceholder from "./BlockPlaceholder";
import DraggableBlock from "./DraggableBlock";

type Props = {
    isDragging: boolean;
    blocks: Block[];
    onClear: () => void;
    children: any;
}

const DroppablePlaymat = (props: Props) => {
  const { isDragging, blocks, children } = props;

  return (
    <Droppable droppableId="playmat" direction="horizontal">
      {(provided: DroppableProvided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="Section PlayMat"
        >
          {blocks.map((block, index) => (
            <DraggableBlock key={block.id} block={block} index={index} />
          ))}
          {isDragging && blocks.length === 0 && <BlockPlaceholder />}
          {provided.placeholder}
          {children}
        </div>
      )}
    </Droppable>
  );
};

export default DroppablePlaymat;

import { shuffle } from "../../../services/shuffle";

export type ImageMatchGamePages = {
  correctPage: Page;
  wrongPages: Page[];
  allPages: Page[];
};

export class Story {
  constructor(public readonly name: string, public readonly pages: Page[]) {}

  getPreviousPage(currentPage?: Page): Page | undefined {
    if (!currentPage) return;

    const index = this.pages.findIndex((p) => p.text === currentPage.text);
    const previousPage = this.pages[index - 1];
    return previousPage ? previousPage : undefined;
  }

  getNextPage(currentPage?: Page): Page | undefined {
    if (!currentPage) return;

    const index = this.pages.findIndex((p) => p.text === currentPage.text);
    const nextPage = this.pages[index + 1];
    return nextPage ? nextPage : undefined;
  }

  hasPreviousPage(currentPage?: Page): boolean {
    return !!this.getPreviousPage(currentPage);
  }

  hasNextPage(currentPage?: Page): boolean {
    return !!this.getNextPage(currentPage);
  }

  getPagesForImageMatchGame(): ImageMatchGamePages {
    const correctPage = this.getRandomPage();
    const wrongPages = [this.getRandomPage(), this.getRandomPage()];

    return {
      correctPage,
      wrongPages,
      allPages: shuffle([correctPage, ...wrongPages]),
    };
  }

  getRandomPage(pages?: Page[]): Page {
    const array = pages || this.pages;
    return array[Math.floor(Math.random() * array.length)];
  }

  static async TheTrap(): Promise<Story> {
    return new Story("The Trap!", [
      await Page.create(
        1,
        "Mags and Jax have flat brass slabs. The brass slabs were in Mags's bag.",
        "the-trap/page-1.png"
      ),
      await Page.create(
        2,
        "Las is a bad crab. In a snap, he grabs the slab from the bag.",
        "the-trap/page-2.png"
      ),
      await Page.create(
        3,
        "Las snags it fast and drags it to his pad. It is in a trap.",
        "the-trap/page-3.png"
      ),
      await Page.create(
        4,
        "Drat! Mags is mad and yaps at Jax. They have a spat.",
        "the-trap/page-4.png"
      ),
      await Page.create(
        5,
        "Las is a brat! Mags and Jax want the slab.",
        "the-trap/page-5.png"
      ),
      await Page.create(
        6,
        "They flag a pal to get to Las's pad. They want to find the slab.",
        "the-trap/page-6.png"
      ),
      await Page.create(
        7,
        "Las brags to Mags and Jax, but he does not blab the trap.",
        "the-trap/page-7.png"
      ),
      await Page.create(
        8,
        "They look on the mat, but do not see the slab. They look in his cap, but no slab.",
        "the-trap/page-8.png"
      ),
      await Page.create(
        9,
        "Tap! Tap! They jab at the rag on the vat. The trap! The rag was Las's trap for the slab.",
        "the-trap/page-9.png"
      ),
      await Page.create(
        10,
        "Mags and Jax are glad to have the flat brass slabs! They clap the slabs!",
        "the-trap/page-10.png"
      ),
      await Page.create(
        11,
        "They bat at it with the slabs! They have crab! ",
        "the-trap/page-11.png"
      ),
    ]);
  }
}

export class Page {
  constructor(
    public readonly number: number,
    public readonly text: string,
    public readonly image: string
  ) {}

  static async create(number: number, text: string, imagePath: string) {
    const imageModule = await import(`../stories/${imagePath}`);
    return new Page(number, text, imageModule.default);
  }
}

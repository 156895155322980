import React from "react";
import { Page } from "../models/story";
import "./MinimizedPage.css";

type Props = {
  page: Page | undefined;
};

const MinimizedPage = (props: Props) => {
  const { page } = props;

  return (
    <div className="minimized-page">
      <img src={page?.image} alt="" className="minimized-page__image" />
      <p className="minimized-page__text">{page?.text}</p>
    </div>
  );
};

export default MinimizedPage;

import React, { useState } from "react";
import { BlockSet, createBlockSets } from "../models/blockSets";
import "./BlockSets.css";

const initialBlockSets = createBlockSets();

type Props = {
  onBlockSetClick: (blockSet: BlockSet) => void;
};

const BlockSets = (props: Props) => {
  const { onBlockSetClick } = props;
  const [blockSets] = useState(initialBlockSets);

  return (
    <div className="Section BlockSets">
      {blockSets.map((blockSet: BlockSet) => (
        <div key={blockSet.name}>
          <button onClick={() => onBlockSetClick(blockSet)}>
            {blockSet.name}
          </button>
        </div>
      ))}
    </div>
  );
};

export default BlockSets;

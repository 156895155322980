import click from "../services/sound.js";

export const deleteBlockFromPlaymat = (blocks = [], index) => {
  const newBlocks = [...blocks];
  newBlocks.splice(index, 1);
  return newBlocks;
};

export const reorderBlocksInPlaymat = (blocks = [], sourceIndex, destinationIndex) => {
  // guard clause
  if (!blocks || blocks.length === 0) {
    return [];
  }

  if (!blocks[sourceIndex]) {
    throw new Error("Invalid sourceIndex parameter");
  }

  if (!blocks[destinationIndex]) {
    throw new Error("Invalid destinationIndex parameter");
  }

  const playmatBlocks = [...blocks];

  // Remove the source block from our playmat blocks
  const removedBlocks = playmatBlocks.splice(sourceIndex, 1);
  const removedBlock = removedBlocks[0];

  // Now splice back in the remove block at the destination index
  playmatBlocks.splice(destinationIndex, 0, removedBlock);

  click.play();
  return playmatBlocks;
};

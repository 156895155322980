import React from "react";
import { Page, Story } from "../models/story";
import MinimizedPage from "./MinimizedPage";
import "./MinimizedStory.css";

type Props = {
  story: Story;
  leftPage: Page | undefined;
  rightPage: Page | undefined;
  onClick: () => void;
};

const MinimizedStory = (props: Props) => {
  const { onClick, leftPage, rightPage } = props;

  return (
    <div className="minimized-story" onClick={onClick}>
      <MinimizedPage page={leftPage} />
      <MinimizedPage page={rightPage} />
    </div>
  );
};

export default MinimizedStory;

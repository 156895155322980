import React from "react";
import { Draggable } from "react-beautiful-dnd";
import BlockImage from "../assets/block.png";
import Block from "../models/block";
import "./DraggableBlock.css";

type Props = {
  block: Block;
  index: number;
  onClick?: (block: Block) => void;
};

const DraggableBlock = (props: Props) => {
  const { block, index, onClick } = props;

  const handleBlockClick = (block: Block) => {
    if (onClick) {
      onClick(block)
    }
  }

  const isHexColorName = block.name.startsWith("#");

  return (
    <Draggable draggableId={block.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          className="Block"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => handleBlockClick(block)}
        >
          <img
            src={BlockImage}
            className="BlockImage"
            alt={block.name}
          />
          <div
            style={{
              color: block.textColor,
            }}
            className="BlockContent"
          >
            {isHexColorName ? (
              <div
                className="BlockCircle"
                style={{
                  backgroundColor: block.name,
                }}
              />
            ) : (
              block.name
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableBlock;

import Block from "./block";

export class BlockSet {
  constructor(public readonly name: string, public readonly blocks: Block[]) {}
}

export const createBlockSets = () => [
  new BlockSet("Phonological Awareness", [
    new Block("#FF2E94"),
    new Block("#0096FE"),
    new Block("#00C889"),
    new Block("#62DADC"),
    new Block("#009BA2"),
  ]),
  new BlockSet("A-Z", [
    new Block("a", "#FF3E9C"),
    new Block("b", "#339CF3"),
    new Block("c", "#339CF3"),
    new Block("d", "#339CF3"),
    new Block("e", "#FF3E9C"),
    new Block("f", "#339CF3"),
    new Block("g", "#339CF3"),
    new Block("h", "#339CF3"),
    new Block("i", "#FF3E9C"),
    new Block("j", "#339CF3"),
    new Block("k", "#339CF3"),
    new Block("l", "#339CF3"),
    new Block("m", "#339CF3"),
    new Block("n", "#339CF3"),
    new Block("o", "#FF3E9C"),
    new Block("p", "#339CF3"),
    new Block("qu", "#339CF3"),
    new Block("r", "#339CF3"),
    new Block("s", "#339CF3"),
    new Block("t", "#339CF3"),
    new Block("u", "#FF3E9C"),
    new Block("v", "#339CF3"),
    new Block("w", "#339CF3"),
    new Block("x", "#339CF3"),
    new Block("y", "#FF3E9C"),
    new Block("y", "#339CF3"),
    new Block("z", "#339CF3"),
  ]),
  new BlockSet("Digraphs & Trigraphs", [
    new Block("tch", "#0036FA"),
    new Block("dge", "#0036FA"),
    new Block("wh", "#0036FA"),
    new Block("ch", "#0036FA"),
    new Block("ck", "#0036FA"),
    new Block("sh", "#0036FA"),
    new Block("th", "#0036FA"),
  ]),
  new BlockSet("Glued Sounds", [
    new Block("all", "#00C55D"),
    new Block("am", "#00C55D"),
    new Block("an", "#00C55D"),
    new Block("ang", "#00C55D"),
    new Block("ing", "#00C55D"),
    new Block("ong", "#00C55D"),
    new Block("ung", "#00C55D"),
    new Block("olt", "#00C55D"),
    new Block("ost", "#00C55D"),
    new Block("ild", "#00C55D"),
    new Block("ank", "#00C55D"),
    new Block("ink", "#00C55D"),
    new Block("onk", "#00C55D"),
    new Block("unk", "#00C55D"),
    new Block("ind", "#00C55D"),
    new Block("ost", "#00C55D"),
  ]),
  new BlockSet("Consonant Blends", [
    new Block("bl", "#34A0FF"),
    new Block("cl", "#34A0FF"),
    new Block("fl", "#34A0FF"),
    new Block("gl", "#34A0FF"),
    new Block("pl", "#34A0FF"),
    new Block("sl", "#34A0FF"),
    new Block("sl", "#34A0FF"),
    new Block("sc", "#34A0FF"),
    new Block("sk", "#34A0FF"),
    new Block("sm", "#34A0FF"),
    new Block("sn", "#34A0FF"),
    new Block("sp", "#34A0FF"),
    new Block("st", "#34A0FF"),
    new Block("sw", "#34A0FF"),
    new Block("cr", "#34A0FF"),
    new Block("dr", "#34A0FF"),
    new Block("fr", "#34A0FF"),
    new Block("gr", "#34A0FF"),
    new Block("pr", "#34A0FF"),
    new Block("tr", "#34A0FF"),
    new Block("tw", "#34A0FF"),
    new Block("str", "#34A0FF"),
    new Block("scr", "#34A0FF"),
    new Block("shr", "#34A0FF"),
    new Block("spr", "#34A0FF"),
    new Block("squ", "#34A0FF"),
    new Block("thr", "#34A0FF"),
  ]),
  new BlockSet("Vowel Teams", [
    new Block("eigh", "#FF3598"),
    new Block("ie", "#FF3598"),
    new Block("ay", "#FF3598"),
    new Block("au", "#FF3598"),
    new Block("er", "#FF3598"),
    new Block("ar", "#FF3598"),
    new Block("augh", "#FF3598"),
    new Block("ow", "#FF3598"),
    new Block("aw", "#FF3598"),
    new Block("ey", "#FF3598"),
    new Block("oy", "#FF3598"),
    new Block("e", "#FF3598"),
    new Block("a", "#FF3598"),
    new Block("ir", "#FF3598"),
    new Block("ai", "#FF3598"),
    new Block("ee", "#FF3598"),
    new Block("ough", "#FF3598"),
    new Block("ur", "#FF3598"),
    new Block("o", "#FF3598"),
    new Block("oi", "#FF3598"),
    new Block("oa", "#FF3598"),
    new Block("igh", "#FF3598"),
    new Block("ou", "#FF3598"),
    new Block("or", "#FF3598"),
    new Block("oe", "#FF3598"),
    new Block("i", "#FF3598"),
    new Block("oo", "#FF3598"),
    new Block("u", "#FF3598"),
    new Block("ui", "#FF3598"),
    new Block("ew", "#FF3598"),
    new Block("ue", "#FF3598"),
    new Block("oe", "#FF3598"),
    new Block("ea", "#FF3598"),
  ]),
  new BlockSet("Consonant LE", [
    new Block("ble", "#065794"),
    new Block("cle", "#065794"),
    new Block("dle", "#065794"),
    new Block("fle", "#065794"),
    new Block("gle", "#065794"),
    new Block("kle", "#065794"),
    new Block("ple", "#065794"),
    new Block("sle", "#065794"),
    new Block("tle", "#065794"),
    new Block("zle", "#065794"),
  ]),
  new BlockSet("Silent Letters", [
    new Block("mb", "#04138B"),
    new Block("wr", "#04138B"),
    new Block("gn", "#04138B"),
    new Block("kn", "#04138B"),
    new Block("ph", "#04138B"),
    new Block("gh", "#04138B"),
  ]),
  new BlockSet("Prefixes", [
    new Block("il", "#F77C8A"),
    new Block("ir", "#F77C8A"),
    new Block("fore", "#F77C8A"),
    new Block("in", "#F77C8A"),
    new Block("em", "#F77C8A"),
    new Block("inter", "#F77C8A"),
    new Block("pro", "#F77C8A"),
    new Block("dis", "#F77C8A"),
    new Block("pre", "#F77C8A"),
    new Block("un", "#F77C8A"),
    new Block("ex", "#F77C8A"),
    new Block("sub", "#F77C8A"),
    new Block("e", "#F77C8A"),
    new Block("de", "#F77C8A"),
    new Block("trans", "#F77C8A"),
    new Block("re", "#F77C8A"),
    new Block("im", "#F77C8A"),
    new Block("non", "#F77C8A"),
    new Block("col", "#F77C8A"),
    new Block("mis", "#F77C8A"),
    new Block("con", "#F77C8A"),
    new Block("en", "#F77C8A"),
    new Block("a", "#F77C8A"),
    new Block("com", "#F77C8A"),
    new Block("on", "#F77C8A"),
  ]),
  new BlockSet("Suffixes", [
    new Block("s", "#0B1014"),
    new Block("es", "#0B1014"),
    new Block("ed", "#0B1014"),
    new Block("ing", "#0B1014"),
    new Block("en", "#0B1014"),
    new Block("est", "#0B1014"),
    new Block("ly", "#0B1014"),
    new Block("less", "#0B1014"),
    new Block("ion", "#0B1014"),
    new Block("ous", "#0B1014"),
    new Block("or", "#0B1014"),
    new Block("ible", "#0B1014"),
    new Block("ful", "#0B1014"),
    new Block("ness", "#0B1014"),
    new Block("ic", "#0B1014"),
    new Block("ment", "#0B1014"),
    new Block("y", "#0B1014"),
    new Block("ate", "#0B1014"),
    new Block("ite", "#0B1014"),
    new Block("able", "#0B1014"),
    new Block("ture", "#0B1014"),
    new Block("ive", "#0B1014"),
    new Block("er", "#0B1014"),
    new Block("al", "#0B1014"),
    new Block("nce", "#0B1014"),
  ]),
  new BlockSet("Greek Roots", [
    new Block("phon", "#21939C"),
    new Block("photo", "#21939C"),
    new Block("gram", "#21939C"),
    new Block("graph", "#21939C"),
    new Block("tele", "#21939C"),
    new Block("meter", "#21939C"),
    new Block("bio", "#21939C"),
    new Block("micro", "#21939C"),
    new Block("pod", "#21939C"),
    new Block("techn", "#21939C"),
    new Block("scope", "#21939C"),
    new Block("chron", "#21939C"),
    new Block("ped", "#21939C"),
    new Block("logy", "#21939C"),
    new Block("para", "#21939C"),
    new Block("mech", "#21939C"),
    new Block("arch", "#21939C"),
    new Block("geo", "#21939C"),
    new Block("auto", "#21939C"),
    new Block("peri", "#21939C"),
    new Block("ast", "#21939C"),
    new Block("sphere", "#21939C"),
    new Block("semi", "#21939C"),
    new Block("log", "#21939C"),
    new Block("mon", "#21939C"),
    new Block("poly", "#21939C"),
    new Block("gon", "#21939C"),
    new Block("aud", "#21939C"),
    new Block("bi", "#21939C"),
    new Block("cycle", "#21939C"),
  ]),
  new BlockSet("Latin Roots", [
    new Block("form", "#00E3E1"),
    new Block("port", "#00E3E1"),
    new Block("rupt", "#00E3E1"),
    new Block("tract", "#00E3E1"),
    new Block("scrib", "#00E3E1"),
    new Block("script", "#00E3E1"),
    new Block("spec", "#00E3E1"),
    new Block("stru", "#00E3E1"),
    new Block("flect", "#00E3E1"),
    new Block("flex", "#00E3E1"),
    new Block("dic", "#00E3E1"),
    new Block("fer", "#00E3E1"),
    new Block("mit", "#00E3E1"),
    new Block("miss", "#00E3E1"),
    new Block("duc", "#00E3E1"),
    new Block("fin", "#00E3E1"),
    new Block("lit", "#00E3E1"),
    new Block("ver", "#00E3E1"),
    new Block("cred", "#00E3E1"),
    new Block("pel", "#00E3E1"),
    new Block("sec", "#00E3E1"),
    new Block("fac", "#00E3E1"),
    new Block("fect", "#00E3E1"),
    new Block("fic", "#00E3E1"),
    new Block("jec", "#00E3E1"),
    new Block("fec", "#00E3E1"),
    new Block("lect", "#00E3E1"),
    new Block("pen", "#00E3E1"),
    new Block("cis", "#00E3E1"),
    new Block("ped", "#00E3E1"),
  ]),
];

export default createBlockSets;

/**
 * Creates a card that the student will spell with their blocks
 */
export default class Card {
  constructor(public readonly text: string, public readonly imagePath: string) {}

  /**
   * Pull a random card from our
   */
  static async random(): Promise<Card> {
    const data = cardOptions[Math.floor(Math.random() * cardOptions.length)]
    const imageModule = await import(`../images/${data.imageName}`)
    return new Card(data.text, imageModule.default);
  }
}

const cardOptions = [
  {text: "quack", imageName: "quack.png"},
  {text: "hip", imageName: "hip.png"},
  {text: "jam", imageName: "jam.png"},
  {text: "kit", imageName: "kit.png"},
  {text: "nest", imageName: "nest.png"},
  {text: "bag", imageName: "bag.png"},
  {text: "log", imageName: "log.png"},
  {text: "bug", imageName: "bug.png"},
  {text: "can", imageName: "can.png"},
  {text: "edge", imageName: "edge.png"},
  {text: "doll", imageName: "doll.png"},
  {text: "fan", imageName: "fan.png"},
  {text: "up", imageName: "up.png"},
  {text: "fish", imageName: "fish.png"},
  {text: "leg", imageName: "leg.png"},
  {text: "pig", imageName: "pig.png"},
  {text: "gum", imageName: "gum.png"},
  {text: "ham", imageName: "ham.png"},
  {text: "jump", imageName: "jump.png"},
  {text: "map", imageName: "map.png"},
  {text: "sun", imageName: "sun.png"},
  {text: "tent", imageName: "tent.png"},
  {text: "vest", imageName: "vest.png"},
  {text: "well", imageName: "well.png"},
  {text: "box", imageName: "box.png"},
  {text: "zip", imageName: "zip.png"},
  {text: "dog", imageName: "dog.png"},
  {text: "web", imageName: "web.png"},
  {text: "wall", imageName: "wall.png"},
  {text: "hat", imageName: "hat.png"},
  {text: "ram", imageName: "ram.png"},
  {text: "net", imageName: "net.png"},
  {text: "bat", imageName: "bat.png"},
  {text: "ball", imageName: "ball.png"},
  {text: "pan", imageName: "pan.png"},
  {text: "six", imageName: "six.png"},
  {text: "fox", imageName: "fox.png"},
  {text: "call", imageName: "call.png"},
];

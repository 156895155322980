import React from "react";
import "./PlaymatClearButton.css";

type Props = {
    onClick: () => void
}

const PlaymatClearButton = (props: Props) => {
    return (
        <button className="PlaymatClearButton" onClick={props.onClick}>Clear Playmat</button>
    );
};

export default PlaymatClearButton;
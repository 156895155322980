/**
 * Creates a block data structure for the app
 *
 * @param {String} name - Can be either a string of letters or a hexidecimal color value (e.g. #FF2E94)
 * @param {String} textColor - The hexidecimal color value for a block's text. Defaults to black.
 */
export default class Block {
  public readonly id: string;

  constructor(
    public readonly name: string,
    public readonly textColor: string = "#000000"
  ) {
    this.id = __makeId();
  }
}

// Create a random uniq ID so we can use duplicate blocks
// @see - https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
const __makeId = (length = 32) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

import React, { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import "./App.css";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import DraggableBlock from "./components/DraggableBlock";
import DroppablePlayment from "./components/DroppablePlaymat";
import BlockSets from "./components/BlockSets";
import Block from "./models/block";
import DroppableTrash from "./components/DroppableTrash";
import CardGamePlayButton from "./features/card-game/components/PlayButton";
import CardGame from "./features/card-game/CardGame";
import StoryPlayButton from "./features/story/components/PlayButton";
import StoryGame from "./features/story/StoryGame";
import PlaymatClearButton from "./components/PlaymatClearButton";
import { deleteBlockFromPlaymat, reorderBlocksInPlaymat } from "./services/playmat";
import click from "./services/sound.js";

library.add(faTrashCan);

const DEFAULT_BLOCK_SET = { name: null, blocks: [] };

function App() {
  const [blockSet, setBlockSet] = useState(DEFAULT_BLOCK_SET);
  const [blocks, setBlocks] = useState([]);
  const [isPlayingCardGame, setIsPlayingCardGame] = useState(false);
  const [isReadingStory, setIsReadingStory] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const onDragStart = (result) => {
    console.log(result);
    setIsDragging(true);
  };

  const onDragEnd = (result) => {
    const { source = {}, destination = {} } = result;

    // Moving block to playmat
    if (source.droppableId === "blockset" && destination.droppableId === "playmat") {
      console.log("moving block to playmat, add new block", source, destination.index);
      const sourceBlock = blockSet.blocks[result.source.index];
      const playmatBlocks = [...blocks];

      // create new block so we can generate a unique id behind the scenes
      const newBlock = new Block(sourceBlock.name, sourceBlock.textColor);
      click.play();

      // add the new block based on our destination index so it correctly drops
      // in the user's desired order
      playmatBlocks.splice(destination.index, 0, newBlock);

      // now set blocks to update our state and rerender ui
      setBlocks(playmatBlocks);
    }

    // TODO: source and destination are the same. We should reorder
    if (source.droppableId === "playmat" && destination.droppableId === "playmat") {
      const playmatBlocks = reorderBlocksInPlaymat(blocks, source.index, destination.index);
      setBlocks(playmatBlocks);
    }

    // Moving playmat block to trash
    if (source.droppableId === "playmat" && destination.droppableId === "trash") {
      const newBlocks = deleteBlockFromPlaymat(blocks, source.index);
      setBlocks(newBlocks);
    }

    setIsDragging(false);
  };

  const addBlockToPlaymat = (block) => {
    // create new block so we can generate a unique id behind the scenes
    const newBlock = new Block(block.name, block.textColor);
    click.play();

    // now set blocks to update our state and rerender ui
    setBlocks([...blocks, newBlock]);
  }

  const clearPlaymat = () => {
    setBlocks([]);
  };

  const playCardGame = () => {
    setIsReadingStory(false)
    setIsPlayingCardGame(true)
  };

  const playStory = () => {
    setIsPlayingCardGame(false)
    setIsReadingStory(true)
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>

        {/* BlockSets for students to select and drag */}
        <div style={{ flexGrow: 1 }}>
          <BlockSets onBlockSetClick={setBlockSet} />
          <Droppable
            droppableId="blockset"
            direction="horizontal"
            // disable drop because nothing should be dropped in our block set
            isDropDisabled={true}
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps} className={"Section BlockSet"}>
                {blockSet.blocks.map((block, index) => (
                  <DraggableBlock key={block.id} block={block} index={index} onClick={addBlockToPlaymat} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>

        {/* Droppable playmat where students can arrange their word */}
        <div>
          <DroppablePlayment isDragging={isDragging} blocks={blocks}>

            <PlaymatClearButton onClick={clearPlaymat} />
            <DroppableTrash isDragging={isDragging} />
          </DroppablePlayment>
        </div>

        <div className="FloatingButtons">
          <CardGamePlayButton onClick={playCardGame} />
          <StoryPlayButton onClick={playStory} />
        </div>

        {isPlayingCardGame && <CardGame onCancel={() => setIsPlayingCardGame(false)} />}
        {isReadingStory && <StoryGame onCancel={() => setIsReadingStory(false)} />}
      </div>
    </DragDropContext>
  );
}

export default App;

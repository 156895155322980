import React from "react";
import { Droppable, DroppableProvided } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./DroppableTrash.css";

type Props = {
  isDragging: boolean;
};

const DroppableTrash = (props: Props) => {
    const { isDragging } = props;

    return (
      <Droppable droppableId="trash" direction="horizontal">
        {(provided: DroppableProvided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="TrashBin"
            style={{
              opacity: isDragging ? 0.5 : 0,
            }}
          >
            <FontAwesomeIcon icon={"trash-can"} className="TrashBinIcon" />
            Drag here to delete
          </div>
        )}
      </Droppable>
    )
  }

  export default DroppableTrash;
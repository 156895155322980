import React, { useEffect, useState } from "react";
import "./CardGame.css";
import Card from "./models/card";

type Props = {
  onCancel: () => void;
};

enum CardSide {
  FRONT = "front",
  BACK = "back",
}

/**
 * Component to render a random card for the student to spell with their blocks
 */
const CardGame = (props: Props) => {
  const { onCancel } = props;
  const [card, setCard] = useState<Card | null>(null);
  const [cardSide, setCardSide] = useState(CardSide.FRONT);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadCard();
  }, []);

  const loadCard = async () => {
    setLoading(true);
    setCardSide(CardSide.FRONT);

    const newCard = await Card.random();

    setCard(newCard);
    setLoading(false);
  };

  if (loading === true || card === null) return null;

  return (
    <div className="CardGameContainer">
      <button className="CancelBtn" onClick={onCancel}>&#x2715;</button>
      <div className="Card">
        {cardSide === CardSide.FRONT ? (
          <>
            <div className="CardContent">
              <img className="CardImage" src={card.imagePath} alt="" />
            </div>
            <div className="CardControls">
              <div>{""}</div>
              <button onClick={() => setCardSide(CardSide.BACK)}>
                Flip &rarr;
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="CardContent">
              <h2 className="CardText">{card.text}</h2>
            </div>
            <div className="CardControls">
              <button onClick={() => setCardSide(CardSide.FRONT)}>
                &larr; Back
              </button>
              <button onClick={loadCard} className="btn btn-primary">
                Play Again!
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CardGame;
